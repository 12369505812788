/* You can add global styles to this file, and also import other style files */
@import "~@angular/material/prebuilt-themes/indigo-pink.css";
@import '~ngx-ui-switch/ui-switch.component.scss';
html {
  box-sizing: border-box;
  height: 100%;
  background-color:  #ffffff;
  font-family: Roboto, sans-serif;
}
body {
  margin: 0;
  padding: 0;
  min-height: 100%;
}
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }

.custom-dialog-container .mat-dialog-container {
  padding: 0;
}
